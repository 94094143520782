<template>
  <div>
    <v-card>
        <v-card-title>Data Uploads</v-card-title>
        <v-card-text>
            <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="tableitems"
  ></v-data-table>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DataUploads",
  data() {
    return {
      data: [],
      tableitems:10,
      headers:[
          { text: 'Municipal Name', value: 'munname' },
          { text: 'Upload Date', value: 'logdate' },
          { text: 'Farms', align:'end',value: 'farms' },
          { text: 'Crops', align:'end',value: 'crops' },
          { text: 'Livestock',align:'end', value: 'livestock' },
          { text: 'Poultry', align:'end',value: 'poultry' },
          { text: 'Trees', align:'end',value: 'trees' },
          // { text: 'Fishery', value: 'fishery' },
        ],
    };
  },

  mounted(){
    this.getData()
  },

  methods: {
    async getData(){
      await this.$store.dispatch('muncity/getAlluploads')
      this.data=await this.$store.state.muncity.alluploads
    }
  },
};
</script>
